import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CommGuide from '../pages/CommGuide';
import CookiePolicy from '../pages/CookiePolicy';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import Layout from './Layout';

export default function AppRoutes() {
  return (
    <Router>
      <Routes>
        <Route path="/pages" element={<Layout noFooter />}>
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="cookie-policy" element={<CookiePolicy />} />
          <Route path="comm-guide" element={<CommGuide />} />
        </Route>
        <Route path="/" element={<Layout />}>
          <Route index element={<div>Welcome to MY VERSION</div>} />
        </Route>
        <Route path="*" element={<div />} />
      </Routes>
    </Router>
  );
}
