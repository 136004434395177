var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
var defaultOptions = {};
export var Enum_Tank_Env;
(function (Enum_Tank_Env) {
    Enum_Tank_Env["Brackishwater"] = "brackishwater";
    Enum_Tank_Env["Dry"] = "dry";
    Enum_Tank_Env["Freshwater"] = "freshwater";
    Enum_Tank_Env["Pond"] = "pond";
    Enum_Tank_Env["Saltwater"] = "saltwater";
    Enum_Tank_Env["Terrarium"] = "terrarium";
    Enum_Tank_Env["Vivarium"] = "vivarium";
})(Enum_Tank_Env || (Enum_Tank_Env = {}));
export var Enum_Video_Type;
(function (Enum_Video_Type) {
    Enum_Video_Type["Youtube"] = "youtube";
})(Enum_Video_Type || (Enum_Video_Type = {}));
export var Platform;
(function (Platform) {
    Platform["Android"] = "android";
    Platform["Ios"] = "ios";
    Platform["Web"] = "web";
})(Platform || (Platform = {}));
export var CardFragmentDoc = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    fragment Card on CardEntity {\n  id\n  attributes {\n    content\n    time\n    categories {\n      data {\n        id\n        attributes {\n          name\n        }\n      }\n    }\n  }\n}\n    "], ["\n    fragment Card on CardEntity {\n  id\n  attributes {\n    content\n    time\n    categories {\n      data {\n        id\n        attributes {\n          name\n        }\n      }\n    }\n  }\n}\n    "])));
export var CategoryFragmentDoc = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    fragment Category on CategoryEntity {\n  id\n  attributes {\n    name\n  }\n}\n    "], ["\n    fragment Category on CategoryEntity {\n  id\n  attributes {\n    name\n  }\n}\n    "])));
export var ReportFragmentDoc = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    fragment Report on ReportEntity {\n  id\n  attributes {\n    type\n  }\n}\n    "], ["\n    fragment Report on ReportEntity {\n  id\n  attributes {\n    type\n  }\n}\n    "])));
export var UploadFileFragmentDoc = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    fragment UploadFile on UploadFileEntity {\n  id\n  attributes {\n    url\n    formats\n  }\n}\n    "], ["\n    fragment UploadFile on UploadFileEntity {\n  id\n  attributes {\n    url\n    formats\n  }\n}\n    "])));
export var UserFragmentDoc = gql(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    fragment User on UsersPermissionsUserEntity {\n  id\n  attributes {\n    username\n    displayName\n    profileUrl\n  }\n}\n    "], ["\n    fragment User on UsersPermissionsUserEntity {\n  id\n  attributes {\n    username\n    displayName\n    profileUrl\n  }\n}\n    "])));
export var TankFragmentDoc = gql(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    fragment Tank on TankEntity {\n  id\n  attributes {\n    name\n    env\n    focusList\n    desc\n    updatedAt\n    createdAt\n    images {\n      data {\n        ...UploadFile\n      }\n    }\n    author {\n      data {\n        ...User\n      }\n    }\n  }\n}\n    ", "\n", ""], ["\n    fragment Tank on TankEntity {\n  id\n  attributes {\n    name\n    env\n    focusList\n    desc\n    updatedAt\n    createdAt\n    images {\n      data {\n        ...UploadFile\n      }\n    }\n    author {\n      data {\n        ...User\n      }\n    }\n  }\n}\n    ", "\n", ""])), UploadFileFragmentDoc, UserFragmentDoc);
export var TankThumbFragmentDoc = gql(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    fragment TankThumb on TankEntity {\n  id\n  attributes {\n    name\n    env\n    author {\n      data {\n        ...User\n      }\n    }\n    images {\n      data {\n        ...UploadFile\n      }\n    }\n  }\n}\n    ", "\n", ""], ["\n    fragment TankThumb on TankEntity {\n  id\n  attributes {\n    name\n    env\n    author {\n      data {\n        ...User\n      }\n    }\n    images {\n      data {\n        ...UploadFile\n      }\n    }\n  }\n}\n    ", "\n", ""])), UserFragmentDoc, UploadFileFragmentDoc);
export var UserMeFragmentDoc = gql(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    fragment UserMe on UsersPermissionsMe {\n  id\n  username\n  displayName\n  profileUrl\n  email\n  confirmed\n  blocked\n}\n    "], ["\n    fragment UserMe on UsersPermissionsMe {\n  id\n  username\n  displayName\n  profileUrl\n  email\n  confirmed\n  blocked\n}\n    "])));
export var VideoFragmentDoc = gql(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    fragment Video on VideoEntity {\n  id\n  attributes {\n    videoId\n    title\n    author {\n      data {\n        ...User\n      }\n    }\n    thumbnail\n    type\n  }\n}\n    ", ""], ["\n    fragment Video on VideoEntity {\n  id\n  attributes {\n    videoId\n    title\n    author {\n      data {\n        ...User\n      }\n    }\n    thumbnail\n    type\n  }\n}\n    ", ""])), UserFragmentDoc);
export var VersionFragmentDoc = gql(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n    fragment Version on VersionEntity {\n  id\n  attributes {\n    shortId\n    video {\n      data {\n        ...Video\n      }\n    }\n    author {\n      data {\n        ...User\n      }\n    }\n  }\n}\n    ", "\n", ""], ["\n    fragment Version on VersionEntity {\n  id\n  attributes {\n    shortId\n    video {\n      data {\n        ...Video\n      }\n    }\n    author {\n      data {\n        ...User\n      }\n    }\n  }\n}\n    ", "\n", ""])), VideoFragmentDoc, UserFragmentDoc);
export var CardsDocument = gql(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n    query cards($filters: CardFiltersInput, $sort: [String]) {\n  cards(filters: $filters, sort: $sort) {\n    data {\n      ...Card\n    }\n  }\n}\n    ", ""], ["\n    query cards($filters: CardFiltersInput, $sort: [String]) {\n  cards(filters: $filters, sort: $sort) {\n    data {\n      ...Card\n    }\n  }\n}\n    ", ""])), CardFragmentDoc);
/**
 * __useCardsQuery__
 *
 * To run a query within a React component, call `useCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCardsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useCardsQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(CardsDocument, options);
}
export function useCardsLazyQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(CardsDocument, options);
}
export var CreateCardDocument = gql(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n    mutation createCard($data: CardInput!) {\n  createCard(data: $data) {\n    data {\n      ...Card\n    }\n  }\n}\n    ", ""], ["\n    mutation createCard($data: CardInput!) {\n  createCard(data: $data) {\n    data {\n      ...Card\n    }\n  }\n}\n    ", ""])), CardFragmentDoc);
/**
 * __useCreateCardMutation__
 *
 * To run a mutation, you first call `useCreateCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCardMutation, { data, loading, error }] = useCreateCardMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCardMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CreateCardDocument, options);
}
export var DeleteCardDocument = gql(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n    mutation deleteCard($id: ID!) {\n  deleteCard(id: $id) {\n    data {\n      ...Card\n    }\n  }\n}\n    ", ""], ["\n    mutation deleteCard($id: ID!) {\n  deleteCard(id: $id) {\n    data {\n      ...Card\n    }\n  }\n}\n    ", ""])), CardFragmentDoc);
/**
 * __useDeleteCardMutation__
 *
 * To run a mutation, you first call `useDeleteCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCardMutation, { data, loading, error }] = useDeleteCardMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCardMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(DeleteCardDocument, options);
}
export var UpdateCardDocument = gql(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n    mutation updateCard($id: ID!, $data: CardInput!) {\n  updateCard(id: $id, data: $data) {\n    data {\n      ...Card\n    }\n  }\n}\n    ", ""], ["\n    mutation updateCard($id: ID!, $data: CardInput!) {\n  updateCard(id: $id, data: $data) {\n    data {\n      ...Card\n    }\n  }\n}\n    ", ""])), CardFragmentDoc);
/**
 * __useUpdateCardMutation__
 *
 * To run a mutation, you first call `useUpdateCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCardMutation, { data, loading, error }] = useUpdateCardMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCardMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(UpdateCardDocument, options);
}
export var CategoriesDocument = gql(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n    query categories($filters: CategoryFiltersInput, $sort: [String]) {\n  categories(filters: $filters, sort: $sort) {\n    data {\n      ...Category\n    }\n  }\n}\n    ", ""], ["\n    query categories($filters: CategoryFiltersInput, $sort: [String]) {\n  categories(filters: $filters, sort: $sort) {\n    data {\n      ...Category\n    }\n  }\n}\n    ", ""])), CategoryFragmentDoc);
/**
 * __useCategoriesQuery__
 *
 * To run a query within a React component, call `useCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useCategoriesQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(CategoriesDocument, options);
}
export function useCategoriesLazyQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(CategoriesDocument, options);
}
export var UploadDocument = gql(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n    mutation upload($refId: ID, $ref: String, $field: String, $info: FileInfoInput, $file: Upload!) {\n  upload(refId: $refId, ref: $ref, field: $field, file: $file, info: $info) {\n    data {\n      ...UploadFile\n    }\n  }\n}\n    ", ""], ["\n    mutation upload($refId: ID, $ref: String, $field: String, $info: FileInfoInput, $file: Upload!) {\n  upload(refId: $refId, ref: $ref, field: $field, file: $file, info: $info) {\n    data {\n      ...UploadFile\n    }\n  }\n}\n    ", ""])), UploadFileFragmentDoc);
/**
 * __useUploadMutation__
 *
 * To run a mutation, you first call `useUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadMutation, { data, loading, error }] = useUploadMutation({
 *   variables: {
 *      refId: // value for 'refId'
 *      ref: // value for 'ref'
 *      field: // value for 'field'
 *      info: // value for 'info'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(UploadDocument, options);
}
export var CreateReportDocument = gql(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n    mutation createReport($data: ReportInput!) {\n  createReport(data: $data) {\n    data {\n      ...Report\n    }\n  }\n}\n    ", ""], ["\n    mutation createReport($data: ReportInput!) {\n  createReport(data: $data) {\n    data {\n      ...Report\n    }\n  }\n}\n    ", ""])), ReportFragmentDoc);
/**
 * __useCreateReportMutation__
 *
 * To run a mutation, you first call `useCreateReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReportMutation, { data, loading, error }] = useCreateReportMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateReportMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CreateReportDocument, options);
}
export var CreateTankDocument = gql(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n    mutation createTank($data: TankInput!) {\n  createTank(data: $data) {\n    data {\n      ...Tank\n    }\n  }\n}\n    ", ""], ["\n    mutation createTank($data: TankInput!) {\n  createTank(data: $data) {\n    data {\n      ...Tank\n    }\n  }\n}\n    ", ""])), TankFragmentDoc);
/**
 * __useCreateTankMutation__
 *
 * To run a mutation, you first call `useCreateTankMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTankMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTankMutation, { data, loading, error }] = useCreateTankMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTankMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CreateTankDocument, options);
}
export var DeleteTankDocument = gql(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n    mutation deleteTank($id: ID!) {\n  deleteTank(id: $id) {\n    data {\n      id\n    }\n  }\n}\n    "], ["\n    mutation deleteTank($id: ID!) {\n  deleteTank(id: $id) {\n    data {\n      id\n    }\n  }\n}\n    "])));
/**
 * __useDeleteTankMutation__
 *
 * To run a mutation, you first call `useDeleteTankMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTankMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTankMutation, { data, loading, error }] = useDeleteTankMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTankMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(DeleteTankDocument, options);
}
export var TankDocument = gql(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n    query tank($id: ID!) {\n  tank(id: $id) {\n    data {\n      ...Tank\n    }\n  }\n}\n    ", ""], ["\n    query tank($id: ID!) {\n  tank(id: $id) {\n    data {\n      ...Tank\n    }\n  }\n}\n    ", ""])), TankFragmentDoc);
/**
 * __useTankQuery__
 *
 * To run a query within a React component, call `useTankQuery` and pass it any options that fit your needs.
 * When your component renders, `useTankQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTankQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTankQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(TankDocument, options);
}
export function useTankLazyQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(TankDocument, options);
}
export var TanksDocument = gql(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n    query tanks($filters: TankFiltersInput, $sort: [String], $pagination: PaginationArg) {\n  tanks(filters: $filters, pagination: $pagination, sort: $sort) {\n    data {\n      ...TankThumb\n    }\n  }\n}\n    ", ""], ["\n    query tanks($filters: TankFiltersInput, $sort: [String], $pagination: PaginationArg) {\n  tanks(filters: $filters, pagination: $pagination, sort: $sort) {\n    data {\n      ...TankThumb\n    }\n  }\n}\n    ", ""])), TankThumbFragmentDoc);
/**
 * __useTanksQuery__
 *
 * To run a query within a React component, call `useTanksQuery` and pass it any options that fit your needs.
 * When your component renders, `useTanksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTanksQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      sort: // value for 'sort'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useTanksQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(TanksDocument, options);
}
export function useTanksLazyQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(TanksDocument, options);
}
export var UpdateTankDocument = gql(templateObject_22 || (templateObject_22 = __makeTemplateObject(["\n    mutation updateTank($id: ID!, $data: TankInput!) {\n  updateTank(id: $id, data: $data) {\n    data {\n      ...Tank\n    }\n  }\n}\n    ", ""], ["\n    mutation updateTank($id: ID!, $data: TankInput!) {\n  updateTank(id: $id, data: $data) {\n    data {\n      ...Tank\n    }\n  }\n}\n    ", ""])), TankFragmentDoc);
/**
 * __useUpdateTankMutation__
 *
 * To run a mutation, you first call `useUpdateTankMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTankMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTankMutation, { data, loading, error }] = useUpdateTankMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTankMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(UpdateTankDocument, options);
}
export var ConnectAuthDocument = gql(templateObject_23 || (templateObject_23 = __makeTemplateObject(["\n    mutation ConnectAuth($type: String!, $code: String!, $username: String, $platform: Platform!) {\n  connect(\n    input: {type: $type, code: $code, username: $username, platform: $platform}\n  ) {\n    jwt\n    user {\n      ...UserMe\n    }\n  }\n}\n    ", ""], ["\n    mutation ConnectAuth($type: String!, $code: String!, $username: String, $platform: Platform!) {\n  connect(\n    input: {type: $type, code: $code, username: $username, platform: $platform}\n  ) {\n    jwt\n    user {\n      ...UserMe\n    }\n  }\n}\n    ", ""])), UserMeFragmentDoc);
/**
 * __useConnectAuthMutation__
 *
 * To run a mutation, you first call `useConnectAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectAuthMutation, { data, loading, error }] = useConnectAuthMutation({
 *   variables: {
 *      type: // value for 'type'
 *      code: // value for 'code'
 *      username: // value for 'username'
 *      platform: // value for 'platform'
 *   },
 * });
 */
export function useConnectAuthMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ConnectAuthDocument, options);
}
export var DeleteMeDocument = gql(templateObject_24 || (templateObject_24 = __makeTemplateObject(["\n    mutation DeleteMe($id: ID!) {\n  deleteMe(id: $id) {\n    success\n  }\n}\n    "], ["\n    mutation DeleteMe($id: ID!) {\n  deleteMe(id: $id) {\n    success\n  }\n}\n    "])));
/**
 * __useDeleteMeMutation__
 *
 * To run a mutation, you first call `useDeleteMeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMeMutation, { data, loading, error }] = useDeleteMeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMeMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(DeleteMeDocument, options);
}
export var HelloDocument = gql(templateObject_25 || (templateObject_25 = __makeTemplateObject(["\n    query Hello {\n  hello {\n    text\n  }\n}\n    "], ["\n    query Hello {\n  hello {\n    text\n  }\n}\n    "])));
/**
 * __useHelloQuery__
 *
 * To run a query within a React component, call `useHelloQuery` and pass it any options that fit your needs.
 * When your component renders, `useHelloQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHelloQuery({
 *   variables: {
 *   },
 * });
 */
export function useHelloQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(HelloDocument, options);
}
export function useHelloLazyQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(HelloDocument, options);
}
export var LogoutDocument = gql(templateObject_26 || (templateObject_26 = __makeTemplateObject(["\n    mutation logout {\n  logout {\n    success\n  }\n}\n    "], ["\n    mutation logout {\n  logout {\n    success\n  }\n}\n    "])));
/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(LogoutDocument, options);
}
export var MeDocument = gql(templateObject_27 || (templateObject_27 = __makeTemplateObject(["\n    query Me {\n  meV2 {\n    jwt\n    user {\n      ...UserMe\n    }\n  }\n}\n    ", ""], ["\n    query Me {\n  meV2 {\n    jwt\n    user {\n      ...UserMe\n    }\n  }\n}\n    ", ""])), UserMeFragmentDoc);
/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(MeDocument, options);
}
export function useMeLazyQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(MeDocument, options);
}
export var RefreshTokenDocument = gql(templateObject_28 || (templateObject_28 = __makeTemplateObject(["\n    mutation RefreshToken {\n  refreshToken(input: {renew: true}) {\n    jwt\n  }\n}\n    "], ["\n    mutation RefreshToken {\n  refreshToken(input: {renew: true}) {\n    jwt\n  }\n}\n    "])));
/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutation, { data, loading, error }] = useRefreshTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useRefreshTokenMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(RefreshTokenDocument, options);
}
export var UpdateDisplayNameDocument = gql(templateObject_29 || (templateObject_29 = __makeTemplateObject(["\n    mutation UpdateDisplayName($id: ID!, $displayName: String!) {\n  updateUsersPermissionsUser(id: $id, data: {displayName: $displayName}) {\n    data {\n      ...User\n    }\n  }\n}\n    ", ""], ["\n    mutation UpdateDisplayName($id: ID!, $displayName: String!) {\n  updateUsersPermissionsUser(id: $id, data: {displayName: $displayName}) {\n    data {\n      ...User\n    }\n  }\n}\n    ", ""])), UserFragmentDoc);
/**
 * __useUpdateDisplayNameMutation__
 *
 * To run a mutation, you first call `useUpdateDisplayNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDisplayNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDisplayNameMutation, { data, loading, error }] = useUpdateDisplayNameMutation({
 *   variables: {
 *      id: // value for 'id'
 *      displayName: // value for 'displayName'
 *   },
 * });
 */
export function useUpdateDisplayNameMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(UpdateDisplayNameDocument, options);
}
export var UpdateMeDocument = gql(templateObject_30 || (templateObject_30 = __makeTemplateObject(["\n    mutation UpdateMe($id: ID!, $data: UsersPermissionsUserInput!) {\n  updateMe(id: $id, data: $data) {\n    ...UserMe\n  }\n}\n    ", ""], ["\n    mutation UpdateMe($id: ID!, $data: UsersPermissionsUserInput!) {\n  updateMe(id: $id, data: $data) {\n    ...UserMe\n  }\n}\n    ", ""])), UserMeFragmentDoc);
/**
 * __useUpdateMeMutation__
 *
 * To run a mutation, you first call `useUpdateMeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMeMutation, { data, loading, error }] = useUpdateMeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateMeMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(UpdateMeDocument, options);
}
export var UpdateUserDocument = gql(templateObject_31 || (templateObject_31 = __makeTemplateObject(["\n    mutation UpdateUser($id: ID!, $username: String!) {\n  updateUsersPermissionsUser(id: $id, data: {username: $username}) {\n    data {\n      ...User\n    }\n  }\n}\n    ", ""], ["\n    mutation UpdateUser($id: ID!, $username: String!) {\n  updateUsersPermissionsUser(id: $id, data: {username: $username}) {\n    data {\n      ...User\n    }\n  }\n}\n    ", ""])), UserFragmentDoc);
/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      username: // value for 'username'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(UpdateUserDocument, options);
}
export var UpdateProfilePhotoDocument = gql(templateObject_32 || (templateObject_32 = __makeTemplateObject(["\n    mutation UpdateProfilePhoto($id: ID!, $profileUrl: String!) {\n  updateUsersPermissionsUser(id: $id, data: {profileUrl: $profileUrl}) {\n    data {\n      ...User\n    }\n  }\n}\n    ", ""], ["\n    mutation UpdateProfilePhoto($id: ID!, $profileUrl: String!) {\n  updateUsersPermissionsUser(id: $id, data: {profileUrl: $profileUrl}) {\n    data {\n      ...User\n    }\n  }\n}\n    ", ""])), UserFragmentDoc);
/**
 * __useUpdateProfilePhotoMutation__
 *
 * To run a mutation, you first call `useUpdateProfilePhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfilePhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfilePhotoMutation, { data, loading, error }] = useUpdateProfilePhotoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      profileUrl: // value for 'profileUrl'
 *   },
 * });
 */
export function useUpdateProfilePhotoMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(UpdateProfilePhotoDocument, options);
}
export var UserDocument = gql(templateObject_33 || (templateObject_33 = __makeTemplateObject(["\n    query User($username: String!) {\n  userByUsername(username: $username) {\n    data {\n      ...User\n    }\n  }\n}\n    ", ""], ["\n    query User($username: String!) {\n  userByUsername(username: $username) {\n    data {\n      ...User\n    }\n  }\n}\n    ", ""])), UserFragmentDoc);
/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useUserQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(UserDocument, options);
}
export function useUserLazyQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(UserDocument, options);
}
export var UsernameAvailableDocument = gql(templateObject_34 || (templateObject_34 = __makeTemplateObject(["\n    query UsernameAvailable($id: ID!, $username: String!) {\n  usernameAvailable(id: $id, username: $username) {\n    username\n    available\n  }\n}\n    "], ["\n    query UsernameAvailable($id: ID!, $username: String!) {\n  usernameAvailable(id: $id, username: $username) {\n    username\n    available\n  }\n}\n    "])));
/**
 * __useUsernameAvailableQuery__
 *
 * To run a query within a React component, call `useUsernameAvailableQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsernameAvailableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsernameAvailableQuery({
 *   variables: {
 *      id: // value for 'id'
 *      username: // value for 'username'
 *   },
 * });
 */
export function useUsernameAvailableQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(UsernameAvailableDocument, options);
}
export function useUsernameAvailableLazyQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(UsernameAvailableDocument, options);
}
export var UsersDocument = gql(templateObject_35 || (templateObject_35 = __makeTemplateObject(["\n    query Users($filters: UsersPermissionsUserFiltersInput, $pagination: PaginationArg, $sort: [String]) {\n  usersPermissionsUsers(filters: $filters, pagination: $pagination, sort: $sort) {\n    data {\n      ...User\n    }\n  }\n}\n    ", ""], ["\n    query Users($filters: UsersPermissionsUserFiltersInput, $pagination: PaginationArg, $sort: [String]) {\n  usersPermissionsUsers(filters: $filters, pagination: $pagination, sort: $sort) {\n    data {\n      ...User\n    }\n  }\n}\n    ", ""])), UserFragmentDoc);
/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useUsersQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(UsersDocument, options);
}
export function useUsersLazyQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(UsersDocument, options);
}
export var CreateVersionDocument = gql(templateObject_36 || (templateObject_36 = __makeTemplateObject(["\n    mutation createVersion($data: VersionInput!) {\n  createVersion(data: $data) {\n    data {\n      ...Version\n    }\n  }\n}\n    ", ""], ["\n    mutation createVersion($data: VersionInput!) {\n  createVersion(data: $data) {\n    data {\n      ...Version\n    }\n  }\n}\n    ", ""])), VersionFragmentDoc);
/**
 * __useCreateVersionMutation__
 *
 * To run a mutation, you first call `useCreateVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVersionMutation, { data, loading, error }] = useCreateVersionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateVersionMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CreateVersionDocument, options);
}
export var VersionsDocument = gql(templateObject_37 || (templateObject_37 = __makeTemplateObject(["\n    query versions($filters: VersionFiltersInput, $sort: [String]) {\n  versions(filters: $filters, sort: $sort) {\n    data {\n      ...Version\n    }\n  }\n}\n    ", ""], ["\n    query versions($filters: VersionFiltersInput, $sort: [String]) {\n  versions(filters: $filters, sort: $sort) {\n    data {\n      ...Version\n    }\n  }\n}\n    ", ""])), VersionFragmentDoc);
/**
 * __useVersionsQuery__
 *
 * To run a query within a React component, call `useVersionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVersionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVersionsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useVersionsQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(VersionsDocument, options);
}
export function useVersionsLazyQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(VersionsDocument, options);
}
export var CreateVideoDocument = gql(templateObject_38 || (templateObject_38 = __makeTemplateObject(["\n    mutation createVideo($data: VideoInput!) {\n  createVideo(data: $data) {\n    data {\n      ...Video\n    }\n  }\n}\n    ", ""], ["\n    mutation createVideo($data: VideoInput!) {\n  createVideo(data: $data) {\n    data {\n      ...Video\n    }\n  }\n}\n    ", ""])), VideoFragmentDoc);
/**
 * __useCreateVideoMutation__
 *
 * To run a mutation, you first call `useCreateVideoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVideoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVideoMutation, { data, loading, error }] = useCreateVideoMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateVideoMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CreateVideoDocument, options);
}
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21, templateObject_22, templateObject_23, templateObject_24, templateObject_25, templateObject_26, templateObject_27, templateObject_28, templateObject_29, templateObject_30, templateObject_31, templateObject_32, templateObject_33, templateObject_34, templateObject_35, templateObject_36, templateObject_37, templateObject_38;
