import { useState } from 'react';
import { FaSignOutAlt } from 'react-icons/fa';
import useAuth from './auth/use-auth';
import LogoBlinkerCenter from './components/LogoBlink';
import SignInModalWrap from './user/SignInModal';

function AuthMenus() {
  const [
    { user, authInit, loading, isLoggedIn },
    {
      //
      // navigate,
      handleLogout,
    },
  ] = useAuth();
  const [open, setOpen] = useState(false);

  if (!authInit || loading) {
    return <LogoBlinkerCenter />;
  }

  return (
    <>
      {isLoggedIn ? (
        <span className="flex justify-end flex-center-y">
          <span className="font-semibold">{user?.displayName}</span>

          <button
            className="btn btn-ghost opacity-60"
            onClick={() => {
              handleLogout();
            }}
          >
            <FaSignOutAlt />
          </button>
        </span>
      ) : (
        <button
          id="sign-in-btn"
          className="btn btn-ghost"
          onClick={() => {
            setOpen(true);
          }}
        >
          Sign In
        </button>
      )}
      <SignInModalWrap open={open} setOpen={setOpen} />
    </>
  );
}

export default AuthMenus;
