export default {
    reply: {
        pageSize: 5,
    },
    tank: {
        maxPhotoLen: 6,
        maxNameLen: 80,
        descMaxLen: 3000,
        splitter: '¦',
        pageSize: 20,
    },
    list: {
        pageSize: 10,
    },
    image: {
        maxWidth: 1200,
    },
    comment: {
        maxLength: 1000,
        pageSize: 10,
    },
    player: {
        threasholdForPlayingPreviousInSec: 2,
        progressJumpSteps: { forward: 15000, backward: 15000 },
    },
    podcast: {
        pageSize: 10,
        showCopyMessageDuration: 1000,
    },
    user: {
        displayNameMaxLen: 30,
        usernameMaxLen: 30,
        handleMaxLen: 20,
    },
    search: {
        seeAllThreshold: 3,
        usersPageSize: 10,
        podcastsPageSize: 10,
    },
};
