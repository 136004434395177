/* eslint-disable @typescript-eslint/no-explicit-any */
import classNames from 'classnames';
import { Component, CSSProperties, HTMLAttributes, ReactNode } from 'react';
import ReactDOM from 'react-dom';

export default class Modal extends Component<
  {
    open?: boolean;
    boxStyle?: CSSProperties | undefined;
    onClickBackdrop?: () => void;
    children: ReactNode;
  } & HTMLAttributes<HTMLDivElement>
> {
  el: any;

  constructor(props: any) {
    super(props);
    // @ts-ignore
    this.el = document.createElement('div');
  }

  componentDidMount() {
    // The portal element is inserted in the DOM tree after
    // the Modal's children are mounted, meaning that children
    // will be mounted on a detached DOM node. If a child
    // component requires to be attached to the DOM tree
    // immediately when mounted, for example to measure a
    // DOM node, or uses 'autoFocus' in a descendant, add
    // state to Modal and only render the children when Modal
    // is inserted in the DOM tree.
    //
    const modalRoot = document.getElementById('modal-root') as HTMLElement;

    modalRoot?.appendChild(this.el);
  }

  componentWillUnmount() {
    const modalRoot = document.getElementById('modal-root') as HTMLElement;
    modalRoot?.removeChild(this.el);
  }

  render() {
    const { className, onClickBackdrop, open, children, style, boxStyle } = this.props;
    if (!open) {
      return ReactDOM.createPortal(null, this.el);
    }

    return ReactDOM.createPortal(
      <div
        className="modal modal-open"
        style={style}
        onClick={() => {
          if (onClickBackdrop) {
            onClickBackdrop();
          }
        }}
      >
        <div
          className={classNames(
            'modal-box w-full max-w-none max-h-screen h-full lg:block lg:h-fit rounded-none lg:rounded bg-base-100 lg:bg-white lg:max-w-lg',
            className
          )}
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={boxStyle}
        >
          {children}
        </div>
      </div>,
      this.el
    );
  }
}
