import { useApolloClient } from '@apollo/client';
import { useLogoutMutation, useMeLazyQuery, UserMeFragment } from '@aquadesk/common-lib';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useRecoilState } from 'recoil';
import useAppConfig from '../app/app-hook';
import { setAccessToken } from '../graphql/client';
import { AuthState } from './auth-state';

export default function useAuth({
  navigateToAuthed,
  navigateToUnAuthed,
}: {
  navigateToAuthed?: string;
  navigateToUnAuthed?: string;
} = {}) {
  const client = useApolloClient();
  const navigate = useNavigate();

  const [appConfig, setAppConfig] = useAppConfig();
  const { authLoading, authInit } = appConfig || {};
  const [authState, setAuthState] = useRecoilState(AuthState);

  const [logoutMutation] = useLogoutMutation({
    onCompleted: () => {
      client.clearStore();
    },
  });

  /**
   * Navigate to user to somewhere when authed
   */
  useEffect(() => {
    if (navigateToAuthed && authInit && authState?.jwt) {
      navigate(navigateToAuthed);
    }
    if (navigateToUnAuthed && authInit && !authState?.jwt) {
      navigate(navigateToUnAuthed);
    }
  }, [authInit, authState?.jwt, navigateToAuthed, navigateToUnAuthed]);

  const handleLogin = async (user?: UserMeFragment | null, jwt?: string | null) => {
    setAccessToken(jwt || authState?.jwt);

    setAuthState({
      jwt: jwt || authState?.jwt,
      user,
    });
  };

  const [fetchMe, { loading }] = useMeLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: async (data) => {
      setAppConfig({ authInit: true, authLoading: false });

      if (data?.meV2) {
        await handleLogin(data.meV2?.user, data.meV2?.jwt);
      }
    },
    onError() {
      setAppConfig({ authInit: true, authLoading: false });
    },
  });

  // auth initalize
  useEffect(() => {
    if (!loading && !authLoading && !authInit) {
      setAppConfig({ authLoading: true, authInit: false });

      fetchMe();
    }
  }, [setAuthState, authLoading, authInit, loading]);

  const handleLogout = useCallback(async () => {
    await logoutMutation({
      onCompleted: () => {
        setAccessToken(null);
        client.clearStore();

        setAppConfig({ authLoading: false, authInit: false });
        setAuthState(null);
      },
    });
  }, [client, setAccessToken, setAppConfig, setAuthState, logoutMutation]);

  return [
    {
      isLoggedIn: !!authState?.jwt,
      authInit,
      loading: authLoading || !authInit || loading,
      user: authState?.user,
      auth: authState?.user,
      authState,
    },
    { handleLogin, handleLogout, navigate, setAuthState, setAppConfig },
  ] as const;
}
