export function getEnvs() {
  const envs = {
    clientUrl: process.env.REACT_APP_CLIENT_DOMAIN,
    BackendUrl: `${process.env.REACT_APP_BACKEND_BASE}/graphql`,
    backendbaseUrl: process.env.REACT_APP_BACKEND_BASE,
    googleClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    isProd: process.env.NODE_ENV === 'production',
  };

  return envs;
}

export default getEnvs();
