import classNames from 'classnames';
import { HTMLAttributes } from 'react';
import Logo from './Logo';

import './scss/blinker.scss';

export default function LogoBlinkerCenter({ className }: HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={classNames('flex-center h-screen', className)}>
      <Logo className="w-20 blink-s1" />
    </div>
  );
}
