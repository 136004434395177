var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import BaseBadWordsFilter from 'bad-words';
import { size } from 'lodash';
// https://github.com/web-mech/badwords/pull/95
var BadWords = /** @class */ (function (_super) {
    __extends(BadWords, _super);
    function BadWords() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    BadWords.prototype.clean = function (string) {
        var _this = this;
        try {
            var joinMatch = this.splitRegex.exec(string);
            var joinString = (joinMatch === null || joinMatch === void 0 ? void 0 : joinMatch[0]) || '';
            return string
                .split(this.splitRegex)
                .map(function (word) {
                // @ts-ignore
                return _this.isProfane(word) ? _this.replaceWord(word) : word;
            })
                .join(joinString);
        }
        catch (e) {
            return string;
        }
    };
    return BadWords;
}(BaseBadWordsFilter));
export function hasBadwords(str) {
    var filter = new BadWords();
    var cleaned = filter.clean(str);
    var hasBad = size(str) > 0 && str !== cleaned;
    return [hasBad, cleaned];
}
