import { ApolloProvider } from '@apollo/client';
import { ToastContainer } from 'react-toastify';
import AppRoutes from './app/AppRoutes';
import { useClient } from './graphql/client';

export default function App() {
  const client = useClient();

  return (
    <ApolloProvider client={client}>
      <AppRoutes />
      <ToastContainer position="bottom-center" />
    </ApolloProvider>
  );
}
