import classNames from 'classnames';
import { ReactNode } from 'react';
import { Outlet } from 'react-router';
import useAuth from '../auth/use-auth';
import AuthMenus from '../AuthMenus';
import LogoBlinkerCenter from '../components/LogoBlink';
import Footer from '../Footer';
import appConfig from './appConfig';

function Layout({ children, noFooter }: { noFooter?: boolean; children?: ReactNode }) {
  const [{ authInit, loading }, { navigate }] = useAuth();

  if (!authInit || loading) {
    return <LogoBlinkerCenter />;
  }

  return (
    <>
      <div className="max-w-lg mx-auto h-full min-h-screen flex flex-col">
        <div className="flex-none flex justify-between py-1 flex-center-y">
          <span
            className={classNames(
              'font-bold text-lg text-white',
              'bg-primary rounded-full',
              'px-3 py-1 cursor-pointer'
            )}
            onClick={() => {
              navigate('/');
            }}
          >
            {appConfig.appName}
          </span>
          <div className="flex flex-1 justify-end">
            <AuthMenus />
          </div>
        </div>
        <div className="flex-1 flex w-full h-full">{children || <Outlet />}</div>
        <div className="flex-none">{!noFooter && <Footer />}</div>
      </div>
      <div id="modal-root"></div>
    </>
  );
}

export default Layout;
