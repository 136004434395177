import { FcGoogle } from 'react-icons/fc';

import { Platform, useConnectAuthMutation } from '@aquadesk/common-lib';
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import classNames from 'classnames';
import { VscChromeClose } from 'react-icons/vsc';
import { Link } from 'react-router-dom';
import appConfig from '../app/appConfig';
import useAuth from '../auth/use-auth';
import envs from '../common/envs';
import Modal from '../components/Modal';
import { getErrorMsg } from '../graphql/utils/error';

function SignInModal({ open, setOpen }: { open?: boolean; setOpen: (n: boolean) => void }) {
  const [, { handleLogin }] = useAuth();

  const [mutate, { loading: connectLoading, error }] = useConnectAuthMutation({
    onCompleted: async (connectData) => {
      if (connectData?.connect.user) {
        await handleLogin(connectData?.connect.user, connectData?.connect.jwt);
        setOpen(false);
      }
    },
  });

  const login = useGoogleLogin({
    flow: 'implicit',
    // hosted_domain: envs.clientUrl,
    onSuccess: async (codeResponse) => {
      await mutate({
        variables: {
          code: codeResponse.access_token,
          type: 'google',
          platform: Platform.Web,
        },
      });
    },
  });

  return (
    <Modal
      open={open}
      className="lg:w-[400px]"
      onClickBackdrop={() => {
        setOpen(false);
      }}
    >
      <div className="flex flex-col h-full">
        <div className="flex-none flex text-3xl text-center justify-end lg:hidden">
          <span
            className="cursor-pointer"
            onClick={() => {
              setOpen(false);
            }}
          >
            <VscChromeClose className="opacity-70 w-6" />
          </span>
        </div>
        <div className="flex-auto flex-center">
          <div className="flex flex-col gap-4">
            <h3 className="font-bold text-3xl text-center">Welcome to {appConfig.appName} 👋</h3>
            <div className="py-4 flex flex-col gap-3">
              <button
                className={classNames(
                  'btn btn-lg gap-2 bg-white hover:bg-white border-0 text-black lg:bg-base-200 lg:hover:bg-base-200',
                  {
                    loading: connectLoading,
                  }
                )}
                onClick={async () => {
                  login();
                }}
              >
                <FcGoogle size="1.4rem" />
                Continue with Google
              </button>
            </div>
            {getErrorMsg(error) && <div className="text-error">{getErrorMsg(error)}</div>}
            <p className="pt-4 opacity-50 hidden">
              <span className="text-xs">
                {
                  'By clicking "Continue with Google", you acknowledge that you agree to MyVersion’s'
                }{' '}
                <Link
                  to="/page/terms"
                  className="link"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  Terms of Service
                </Link>{' '}
                and{' '}
                <Link
                  to="/page/privacy-policy"
                  className="link"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  Privacy Policy.
                </Link>
              </span>
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
}

// eslint-disable-next-line
function SignInModalWrap(props: any) {
  return (
    <GoogleOAuthProvider clientId={envs.googleClientId || ''}>
      <SignInModal {...props} />
    </GoogleOAuthProvider>
  );
}

export default SignInModalWrap;
